<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="70%"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="130px"
        :model="ztVersion"
        :rules="ruleValidate">
        <div class="vm-separate">
          <el-form-item label="公司" prop="companyName">
            <el-input v-model="ztVersion.companyName" :placeholder="$t('common.pleaseSelect')" readonly>
              <el-button slot="append" icon="el-icon-search" @click="$refs.selectParent.open()"></el-button>
            </el-input>
            <company-select ref="selectParent" @select="onSelectParent"></company-select>
          </el-form-item>
          <el-form-item label="唯一标识" prop="fkey">
            <el-input v-model="ztVersion.fkey" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>

        <div class="vm-separate">
          <el-form-item label="服务器地址" prop="serverUrl">
            <el-input v-model="ztVersion.serverUrl" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <div></div>
        </div>
        <div class="vm-separate">
          <el-form-item label="监控服务器地址" prop="monitorHost">
            <el-input v-model="ztVersion.monitorHost" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item label="监控服务器端口号" prop="monitorPort">
            <el-input v-model="ztVersion.monitorPort" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item label="DTU服务器地址" prop="dtuServerHost">
            <el-input v-model="ztVersion.dtuServerHost" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item label="DTU服务器端口号" prop="dtuServerPort">
            <el-input v-model="ztVersion.dtuServerPort" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import CompanySelect from "../company/CompanySelect.vue";

  const moduleName = "ZTVersion";

  export default {
    components: {
      CompanySelect,
    },
    data() {
      return {
        submitLoading: false,
        saveDisabled: false,
        dialogVisible: false,
        contentLoading: false,
        ztVersion: {
          id: 0,
          fkey: "",
          serverUrl: "",
          monitorHost: "",
          monitorPort: "",
          companyId: "",
          companyName: "",
          dtuServerHost: "",
          dtuServerPort: "",
        },
        ruleValidate: {
          fkey: [
            {
              required: true,
              pattern: /^\d{4}$/,
              message: "请输入4位数字",
              trigger: "blur",
            },
          ],
          //          serverUrl: [
          //            {
          //              required: true,
          //              pattern: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/,
          //              message: '请输入ip地址',
          //              trigger: 'blur'
          //            }
          //          ]
        },
      };
    },
    computed: {
      title() {
        return (this.ztVersion.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + "私有平台";
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.ztVersion.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.ztVersion.id).then(res => {
          this.ztVersion = Object.assign(this.ztVersion, res.data);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      onSelectParent(row) {
        this.ztVersion.companyId = row.id;
        this.ztVersion.companyName = row.name;
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            console.log(this.ztVersion);
            this.$api.save(moduleName, this.ztVersion).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.ztVersion.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      onDialogClose() {
        this.ztVersion.companyName = "";
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
